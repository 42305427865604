import React, { useState } from "react";

const LandingPageCtas = () => {
  const CTAs = ["Locations", "Get Quote"];  // CTA labels
  const locations = ["Hyderabad", "Bangalore", "Chennai", "Mumbai"]; // Locations for the dropdown

  const [activeDropdown, setActiveDropdown] = useState(false);  // State to control the dropdown

  const toggleDropdown = () => {
    setActiveDropdown(!activeDropdown);  // Toggle dropdown visibility
  };

  const black = {
    color: "black",
    textDecoration: 'none'
  }
  return (
    <>
      {CTAs.map((CTA, index) => (
        CTA === "Locations" ? (  // Conditionally render for "Locations"
          <div key={index} className="cta-dropdown">
            <button onClick={toggleDropdown}><a style={black} href="tel:+919391202148">+919391202138</a></button>
            {activeDropdown && (  // Show dropdown when activeDropdown is true
              <ul className="dropdown-menu">
                {locations.map((location, i) => (
                  <li key={i}>{location}</li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          <a href="#booknow" style={{ textDecoration: 'none' }}>
  <button
    style={{
      background: '#b8111a',
      color: 'white',
      cursor: 'pointer',
      marginLeft: '12px'
    }}
    className="stepsbutton"
  >
    Get quote now
  </button>
</a> // Regular button for other CTAs
        )
      ))}
    </>
  );
};

export default LandingPageCtas;
