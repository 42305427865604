import React from "react";

export function AddressSubForm({ addType }) {
  return (
    <section className="subFormAdressSection">
      <div className="subFormAdressDiv">
        <div className="subFormAddressContainer">
          <input
            type="text"
            placeholder="Flat/Street/Building"
            className="inputField"
            name={`streetDoor${addType}`}
            required
          />
          <input type="text" placeholder="Landmark" className="inputField" />
          <div className="inputRow">
            <input
              type="text"
              placeholder="Zip"
              className="inputField"
              name={`zipDoor${addType}`}
              required
            />
            <input
              type="text"
              placeholder="City"
              className="inputField"
              name={`cityDoor${addType}`}
              required
            />
            <input
              type="text"
              placeholder="State"
              className="inputField"
              name={`stateDoor${addType}`}
              required
            />
          </div>
        </div>
      </div>
    </section>
  );
}
