import React from "react";
import Packagesimage from '../../img/review images.png';
import bikeparcel from '../../img/bike7.jpg';
import fromhyd from '../../img/Bike.jpg';
import bangtopune from '../../img/banglore to pune.jpg';
import fastestbike from '../../img/fastest bike transport service.jpg';
import bike2 from '../../img/bike8.png';
import bikefromhyd from '../../img/bike transport service from hyderabad.jpg';
import mmb from '../../img/movemybike bike packaging.jpg';
import packed from '../../img/packed-img.jpg';


const Packages = () => {

    const Heading = [
        {
            heading: "Package Services",
            para: "Lorem ipsum dolor sit amet consectetur, adipiscing elit class et sagittis, lobortis donec pharetra venenatis."
        }
    ];

    const imageCards = [
        { id: 1, imageSrc: Packagesimage, packagerName: 'exclusive', Order: 'preordernow' },
        { id: 2, imageSrc: bikeparcel, packagerName: 'exclusive', Order: 'preordernow' },
        { id: 3, imageSrc: fromhyd, packagerName: 'exclusive', Order: 'preordernow' },
        { id: 4, imageSrc: bangtopune, packagerName: 'exclusive', Order: 'preordernow' },
        { id: 5, imageSrc: fastestbike, packagerName: 'exclusive', Order: 'preordernow' },
        { id: 6, imageSrc: bike2, packagerName: 'exclusive', Order: 'preordernow' },
        { id: 7, imageSrc: bikefromhyd, packagerName: 'exclusive', Order: 'preordernow' },
        { id: 8, imageSrc: mmb, packagerName: 'exclusive', Order: 'preordernow' },
        { id: 9, imageSrc: packed, packagerName: 'exclusive', Order: 'preordernow' },
      ];

    return (
        <>

            <section className="imagecards" style={{background:'#F6F6F6'}}>
            <h2 style={{
                marginBottom:'40px',
                fontFamily: 'Satoshi !important'
            }}>
            Package Services
            </h2>
            <div
      className="imageboxesparent"
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)', // Creates 3 columns
        gap: '40px', // Adds space between items
      }}
    >
      {imageCards.map((card) => (
        <div
          key={card.id}
          style={{
            border: '1px solid rgb(221, 221, 221)',
            borderRadius: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.05) 4px 4px 20px 0px',
            overflow: 'hidden',
            background: '#fff',
            padding: '20px',
            width:'100%'
          }}
          className="imageboxes"
        >
          <img
            src={card.imageSrc}
            alt={`Card ${card.id}`}
            style={{
              width: '100%', 
              height: '280px', 
              objectFit: 'cover',
              objectPosition: 'top', 
              borderRadius: '8px'
            }}
          />
          
        </div>
      ))}
    </div>
                        
            </section>
        </>
    );
};

export default Packages;
