import React, { useState, useEffect } from 'react';
import LPMenu from "./LandingPageMenu";
import LPCtas from './LandingPageCalltoactions';
import './LandingPagestyles.css';
import { Link } from 'react-router-dom';

const Landingpageheader = () => {
    const [menuVisible, setMenuVisible] = useState(false);
  
    const toggleMenu = () => {
      setMenuVisible(prevMenuVisible => !prevMenuVisible);
    };

  return (
    <>
      <header className="landing-page-header">
        <div className="Logo-box">
           <Link to="/">
          <img src='https://movemybike.in/static/media/logo.e1c9f590.png' alt="Landing Page Logo" /></Link>
        </div>
        <div className='lpmenu-container'>
          <LPMenu />
        </div>
        <div className="ctas-container">
          <LPCtas />
        </div>
        <div className={`humburger-div ${menuVisible ? 'active' : ''}`} 
          onClick={toggleMenu}>
          <div className="top-bar bar"></div>
          <div className="bottom-bar bar"></div>
        </div>
      </header>
      <section className={`mobile-menu-drawer ${menuVisible ? 'show' : ''}`}>
        <div>
        <LPMenu />
          <div>
          <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
          </div>
        </div>
      </section>
    </>
  );
};


export default Landingpageheader;
