import React from "react";

const LandingpageLocations = () => {
    // Array of locations
    const locations = [
        "Bike Transport from Bangalore to Hyderabad",
        "Bike Transport from Bangalore to Chennai",
        "Bike Transport from Bangalore to Mumbai",
        "Bike Transport from Bangalore to Pune",
        "Bike Transport from Bangalore to Delhi",
        "Bike Transport from Bangalore to Kolkata",
        "Bike Transport from Bangalore to Goa",
        "Bike Transport from Bangalore to Ahmedabad",
        "Bike Transport from Bangalore to Jaipur",
        "Bike Transport from Bangalore to Lucknow",
        "Bike Transport from Bangalore to Chandigarh",
        "Bike Transport from Bangalore to Bhopal"
    ];

    const content = [
        {
            heading: 'Explore the Locations We Serve Across India for Safe and Reliable Bike Transport Services',
            strong: 'We offer bike transport services to cities across India, ensuring timely and secure deliveries.',
            para: 'We offer reliable bike transport services across various locations, ensuring safe and timely delivery. Your bike is handled with care, from pickup at your location to delivery at the destination of choice.'
        }
    ];

    // Styles
    const sectionStyle = {
        padding: '100px 0',
        backgroundColor: '#f6f6f6'
    };

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '20px',
        marginTop: '20px',
        textAlign: 'left',
        fontFamily: 'Movemybike'
    };

    const locationBoxStyle = {
        padding: '15px',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '16px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center'
    };
    const Fff = {
        background: '#f6f6f6',
        padding: '24px',
        borderRadius: '16px',

    }
    const left = {
        textAlign: 'left'
    }
    return (
       <section style={sectionStyle}>
        <div className="bg-white">
            <h2>{content[0].heading}</h2>
            <p className="left">{content[0].para}</p>
            <div style={Fff}>
                <h3 className="plocations">Popular Locations</h3>
                <div style={gridStyle} className="mobile-single-column">
                    {locations.map((location, index) => (
                        <div key={index}>
                            {location}
                        </div>
                    ))}
                </div>
            </div>
        </div>
       </section>
    );
};

export default LandingpageLocations;
