import React from "react";
import LandingpageForm from "../../Landingpagecomponents/LandingpageForm";
import stars from '../../../img/stars.svg';
import ShinyText from '../../Landingpagecomponents/ShinyText';
import GradientText from '../../Landingpagecomponents/GradientText';
import { Link } from "react-router-dom";

    


const NewHero = () => {

  const popularCities = [
    { city: 'Hyderabad', link: '/hyderabad' },
    { city: 'Bangalore', link: '/bangalore' },
    { city: 'Chennai', link: '/chennai' }
  ];
  
  return (
    <>
      <section className="examplehero" id="booknow">
      <div className="starbox">
        <div
        className="stars-container"
         style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: '16px'
        }}>
       <img className="star" src={stars} alt="Stars" style={{ width: '20px', height: '20px' }} />
        <img className="star" src={stars} alt="Stars" style={{ width: '20px', height: '20px' }} />
        <img className="star" src={stars} alt="Stars" style={{ width: '20px', height: '20px' }} />
        <img className="star" src={stars} alt="Stars" style={{ width: '20px', height: '20px' }} />
        
        <ShinyText text="Rated 5/5 From 240+ reviews on Google" disabled={false} speed={3} className='custom-class' />
        </div>
      </div>
      <div><h2 className="parcel-bike"
      style={{
        color: 'white'
      }}
      >Easy Bike Shipping From Your Door to Anywhere! <br></br> <GradientText
      colors={["#f3f5f7", "#4079ff", "rgb(0, 0, 0)", "#fff", "#f6f6f6"]} 
      animationSpeed={3} 
      showBorder={false} 
      className="custom-class" 
    >
       Entire India is With Us
    </GradientText></h2></div>
      <div
      className="normal-form-ct"
      style={{  width: '50%', textAlign: 'center' }}> <LandingpageForm /> </div>
      <div className="popular-cities"
      style={{
        display: 'flex',
        flexDirection:'row',
        flexWrap: 'nowrap',
        columnGap: '14px'
      }}
      > <p style={{
        color:'white',
        margin:'0'
      }}>Popular</p> 

{popularCities.map((cityObj, index) => (
        <Link to={cityObj.link} key={index} style={{
          color:'white',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          marginBottom: '0',
          paddingBottom: '0',
          borderRadius:'50px',
          padding: '8px 16px 4px 16px',
          fontSize:'14px',
          textDecoration: 'none'
        }}>
          {cityObj.city}
        </Link>
      ))}
      {/* {popularCities.map((city, index) => ( <a href="#" 
      
      key={index}>{city}</a> ))} */} </div> 
      <div className="indiamap" style={{
        position: 'absolute',
        bottom: '0',
        marginBottom: '-50px',
        zIndex:'auto',
        display:'none'
      }}>
        <img src="https://framerusercontent.com/images/uzt0NJTSrqjrhiks5GZCM1y4R5c.png" alt="" />
      </div>
      </section>
    </>
  );
};
export default NewHero;
