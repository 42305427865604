import React from "react";
import { Link } from 'react-router-dom';
import './Hyderabad-Bangalore.css';
import Landingpageheader from "../../Landingpagecomponents/Landingpageheader";
import Landingpagehero from "../../Landingpagecomponents/Landingpagehero";
import Landingpagefooter from "../../Landingpagecomponents/Landingpagefooter";
import LandingPageCtas from "../../Landingpagecomponents/LandingPageCalltoactions";
import LandingPageservices from "../../Landingpagecomponents/LandingPageservices";
import Packages from "../../Landingpagecomponents/Packages";
import Reviews from "../../Landingpagecomponents/Reviews";
import SbsGuide from '../../Landingpagecomponents/stepbystepGuide.jsx';
import Features from "../../Landingpagecomponents/Features";
import Faqs from "../../Landingpagecomponents/Faq's";
import LandingpageLocations from "../../Landingpagecomponents/LandingpageLocations";
import BeforeFooter from "../../Landingpagecomponents/Beforefooter";
import NewHero from "./ExampleHero.jsx";
import MapComponent from "../../MapComponent/MapComponent.js";
import Hyderabad from "../../hyderabad.js";


function Bookings() {
    return (
        <>
        <section className="Landing-page">
        {/* <Landingpagehero /> */}
        <NewHero />        
        <Features />
        <LandingPageservices />
        <Packages />
        <MapComponent />
        <Reviews />
        <SbsGuide />
        <Faqs />
            </section>
        </>
    );
}

export default Bookings;
