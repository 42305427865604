import React, { useEffect, useState } from "react";
import NewForm from "../../Landingpagecomponents/NewForm";

import Offers from "../../Landingpagecomponents/Offersscroll";
import { ChargesEstimation } from "../../ChargesEstimation/chargesEstimation";
const HyderabadToBangalore = () => {
    const [formData, setFormData] = useState(null);

    useEffect(() => {
    
        const storedFormData = localStorage.getItem('formData');
        if(storedFormData) {
            setFormData(JSON.parse(storedFormData));
        }
    }, []);
    if (!formData) { return <p>No form data available</p>; }
    return (
        <>
        <section>
            <Offers />
        <div> 
            <ChargesEstimation />
              </div>
        </section>
        </>
    );
};

export default HyderabadToBangalore;
