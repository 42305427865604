import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LandingpageForm = () => {
  const [fromLocation, setFromLocation] = useState("");
  const [toLocation, setToLocation] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const getLocationCode = (location, type) => {
    const locationMap = {
      from: {
        Hyderabad: 2,
        Bangalore: 1,
        Mumbai: 3,
        Delhi: 4,
        Kalkata: 5,
        Chennai: 6,
        Vijayawada: 7,
        Vizag: 8,
        Nellore: 9,
        Tirupati: 10,
        Pune: 11,
        Other: 0,
      },
      to: {
        Hyderabad: 102,
        Bangalore: 101,
        Mumbai: 103,
        Delhi: 104,
        Kalkata: 105,
        Chennai: 106,
        Vijayawada: 107,
        Vizag: 108,
        Nellore: 109,
        Tirupati: 110,
        Pune: 111,
        Other: 0,
      },
    };

    return locationMap[type][location] || 0; // Default to 0 if no match
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the selections
    if (!fromLocation || !toLocation) {
      alert("Please select both From and To locations.");
      return;
    }

    if (fromLocation === toLocation) {
      alert("From and To locations cannot be the same.");
      return;
    }

    const fromLocationCode = getLocationCode(fromLocation, "from");
    const toLocationCode = getLocationCode(toLocation, "to");
    setIsLoading(true); 

    try {
      // Fetch data from the API
      const response = await axios.get(
        `https://api.backend.movemybike.in/v1/cus/fromtoprice?tocityId=${fromLocationCode}&fromcityid=${toLocationCode}`
      );
      // const response = await axios.get(
      //   `https://api.backend.movemybike.in/v1/cus/fromtoprice?tocityId=${fromLocationCode}&fromcityid=${toLocationCode}`
      // );

      console.log(' checking the price value',response.data);

      // Check if the response contains valid data
      if (response.data) {
        localStorage.setItem(
          "formData",
          JSON.stringify({ fromLocation, toLocation })
        );

        const newURL = `/${fromLocation.toLowerCase()}-to-${toLocation.toLowerCase()}`;

        navigate(newURL, {
          state: { fromLocation, toLocation, price: response.data },
        });
      } else {
        console.error("No data found in the response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }

  };

  return (
    <div className="form-container normal-form">
      <form onSubmit={handleSubmit} className="landing-page-form">
        {/* From Location */}
        <div className="from-group">
          <select
            id="fromLocation"
            className="fromlocation"
            value={fromLocation}
            onChange={(e) => setFromLocation(e.target.value)}
            required
          >
            <option value="">From Location</option>
            <option value="Hyderabad">Hyderabad</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Chennai">Chennai</option>
            <option value="Delhi">Delhi</option>
            <option value="Pune">Pune</option>
          </select>
        </div>

        {/* To Location */}
        <div className="to-group">
          <select
            id="toLocation"
            className="tolocation"
            value={toLocation}
            onChange={(e) => setToLocation(e.target.value)}
            required
          >
            <option value="">To Location</option>
            <option value="Hyderabad">Hyderabad</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Chennai">Chennai</option>
            <option value="Delhi">Delhi</option>
            <option value="Pune">Pune</option>
          </select>
        </div>

        <button type="submit" className="Landingpage-submission" disabled={isLoading}>
        {isLoading ? "Loading..." : "Find Routes"}
        </button>
       
      </form>
      {/* Loader */}
      {isLoading && (
  <div
  className="loader"
  style={{
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgb(255, 255, 255)",
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    padding: "20px",
    borderRadius: "8px",
    textAlign: "center",
    zIndex: 1000,
    width:'300px'
  }}
>
  <div
    className="spinner"
    style={{
      width: "40px",
      height: "40px",
      border: "4px solid rgba(0, 0, 0, 0.1)", // Light gray border
      borderTop: "4px solid #000", // Blue top border
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
    }}
  ></div>
  <p style={{ marginTop: "10px", fontSize: "16px" }}>Loading...</p>
</div>

)}

    </div>
  );
};

export default LandingpageForm;

{
  /* Third Row: Name */
}
{
  /* <div className="form-group">
                <input
                    type="text"
                    id="name"
                    className="LPName"
                    placeholder="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div> */
}

{
  /* Fourth Row: Phone Number */
}
{
  /* <div className="form-group">
                <input
                    type="tel"
                    id="phone"
                    placeholder="phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                />
            </div> */
}

{
  /* Submit Button */
}
