import React from "react";
import Quotes from '../../img/carbon_quotes.png';
import stars from '../../img/stars.svg';
import Ravik from '../../img/customer review Ravi K.png';
import Priya from '../../img/customer review  priya M.png';
import Arjun from '../../img/Customer review  Arjun R.png';

const Reviews = () => {

    const Reviewcontent = [
        {
            heading: "Our Customer Love what we do",
            Lpara: "Lorem ipsum dolor sit amet consectetur adipiscing elit sagittis fermentum quam tellus, in curae varius massa tempor volutpat proin quisque dictumst pulvinar,",
            One: "Book Now",
            Two: "Get Estimation"
        }
    ]
    const Reviews = [ { 
      id: 1,
      review: "The bike transport from Hyderabad to Bangalore was smooth and on time. Excellent service", 
      reviewername: "Ravi K", 
      reviewerimage: Ravik, 
       howmanystars: 4, 
      }, 
      { 
        id: 2, 
        review: "I shipped my bike from Chennai to Hyderabad, and it arrived safely with no delays. Highly recommend Move My Bike", 
        reviewername: "Priya M", 
        reviewerimage: Priya, 
         howmanystars: 5, 
        }, 
        { 
        id: 3, 
        review: "Great experience with bike transport from Chennai to Bangalore. Fast, safe, and hassle-free delivery", 
        reviewername: "Arjun R", 
        reviewerimage: Arjun,  
        howmanystars: 3, 
      },
      
     ];

      const authorbox = {
        display: 'flex',
        justifyContent: 'center'
      };
      const reviewbox = {
        marginLeft: '10px'
      }

      const renderStars = (count) => { let starsArray = []; for (let i = 0; i < count; i++) { starsArray.push( <span key={i} dangerouslySetInnerHTML={{ __html: stars }} style={{ width: '15px', height: '15px', display: 'inline-block', marginRight: '2px' }}></span> ); } return starsArray; };

    return(
        <>
       <section className="reviews-section"  style={{
        background: '#F6F6F6',
        padding:'100px auto',
        
       }}>
       <h2>What Our Customers Are Saying       </h2>

       <div className="review-innerbox">
         {Reviews.map((review) => (
           <div key={review.id} 
           style={{ border: '1px solid rgba(221, 221, 221, 1)', borderRadius: '15px', background: '#fff', boxShadow: 'rgba(0, 0, 0, 0.05) 4px 4px 20px 0px', padding: '20px', marginBottom: '20px', }} 
           className="review-box" > 
           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px'
           }}>

             <img src={review.reviewerimage} alt={`${review.reviewername}'s avatar`}
              style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px', 

              opacity: '1',
              borderRadius: '50px',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 6px 15px 0px'
               }} 
              /> 
               </div>
               <h4 style={{ margin: 0, textAlign: 'left'}}>{review.reviewername}</h4>
              <div
               style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:'flex-start'
               }}
              >
              <p>{Array.from({ length: 5 }).map((_, index) => (
        <img key={index} src={stars} alt="Star" style={{ width: '20px', marginRight: '5px' }} />
      ))}</p> 
              <p style={{textAlign:'left'}}>{review.review}</p>
               </div> </div> ))} 
               </div>
       </section>
        </>
    )
};
export default Reviews;