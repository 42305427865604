export const basicInfoData = [
  {
    id: "lead_name",
    placeholder: "your name",
    labelName: "Name",
    sublabel: "",
    inputType: "text",
    inputName: "senderName",
    
  },
  {
    id: "phone",
    placeholder: "your number",
    labelName: "Phone number",
    sublabel: "",
    inputType: "text",
    inputName: "Phone",
    inputName: "senderMobileNumber",
  },
  {
    id: "pickupDate",
    placeholder: "Select Date",
    labelName: "Date",
    sublabel: "",
    inputType: "date",
    inputName: "bookingDate",
  },
  {
    id: "email",
    placeholder: "Enter your email id",
    labelName: "E-mail",
    sublabel: "",
    inputType: "email",
    inputName: "senderEmail",
  },
];

export const otherInfoData = [
  {
    labelName: "Delivery Speed",
    childLabelName: "",
    buttonOneLabel: "Speed",
    buttonTwoLabel: "Standard",
  },
  {
    labelName: "Pickup type",
    childLabelName: "",
    buttonOneLabel: "Door Pickup",
    buttonTwoLabel: "Station Pickup",
  },
  {
    labelName: "Delivery type",
    childLabelName: "",
    buttonOneLabel: "Door Delivery",
    buttonTwoLabel: "Station Delivery",
  },
  {
    labelName: "Damage Insurance Scheme",
    childLabelName: "(Know More)",
    buttonOneLabel: "Yes",
    buttonTwoLabel: "No",
  },
];
