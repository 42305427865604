import React from "react";
import { Link } from "react-router-dom";
import Telangana from '../.././img/telangana.png';
import firestService from "../../img/services One.png";
import Dservices from "../../img/delayed services.png";
import phoneservice from "../../img/phone to phoneservices.png";
import tracking from "../../img/tracking.png";
import StarBorder from './StarBorder';
import bikeparcel from "../../video/bikeparcel.mp4";
import packageBike from '../../img/package bike.png';
import compansation from '../../img/transparent (compensation).png';
import DLDP from '../../img/Door pickup and delivery.png';
import OnlyCompenstation from '../../img/compensation.png';
import map3d from '../../img/Map 3d.png';
import hometohome from '../../img/home to home.svg';
import gps from '../../img/gps.svg';
import doorpick from '../../img/Door Pickup.svg';
import Picktures from '../../img/Pictures of packed bikes provided for customer assurance..svg';
import Monetory from '../../img/Monetary compensation offered for delayed bike deliveries..svg';
import Status from '../../img/Status of transportation will be updated on regular interval.svg';
const Features = () => {

  const Heading = [
    {
        heading : "Features of our Transportation Services",
        CTA : "view all"
    }
  ]
const Locations = [
    {
        heading : "Collaborated",
        CTA : "Official Parcel Logistics Partners with South Central Railway at Kacheguda"
    }
]
const minus ={
    marginBottom: '0',
}
    return (
        <>
        <section className="features-of-transportation">
            <div className="inner-parent">
               <div className=" top parts">
                 <h3>{Heading[0].heading}</h3>
               </div>
               <div className=" bottom">
               <div className="cards">
                <div><img className="card-icon-image" src={doorpick} alt="" /></div>
                <div><p style={minus}>Regular status updates on bike transport via train.
                    </p></div>
               </div>
                <div className="cards">
                <div><img className="card-icon-image" src={Picktures} alt="" /></div>
                <div><p style={minus}>Pictures of packed bikes provided for customer assurance.
                    </p></div>
                </div>
                <div className="cards">
                <div><img className="card-icon-image" src={hometohome} alt="" /></div>
                <div><p style={minus}>Flexible door pickup and delivery as per customer needs.
                    </p></div>
                </div>
                <div className="cards">
                    <div><img className="card-icon-image" src={Monetory} alt="" /></div>
                    <div><p style={minus}>Monetary compensation offered for delayed bike deliveries.
                    </p></div>
                </div>
                <div className="cards">
                <div><img className="card-icon-image" src={Status} alt="" /></div>
                    <div><p style={minus}>Status of transportation will be updated on regular interval
                    </p></div></div> 
                  
                    
                    
                    

               </div>
               
               
            </div>
        </section>
        <section 
        style={{
            width: '100%',
            height:'100%',
            background: '#f6f6f6',
            padding:'100px 0',
            
        }}>
            <div className="collabrated-section" >
                <div className="collabschild">
                    <div >
                    <video 
    autoPlay 
    muted 
    playsInline 
    style={{ width: '100%', height: '100%', borderRadius: '15px' }} 
    onMouseOver={(e) => e.target.setAttribute('controls', 'controls')} 
    onMouseOut={(e) => e.target.removeAttribute('controls')}
>
    <source src={bikeparcel} type="video/mp4" />
</video>

                    </div>
                </div>
                <div className="collabschild"
                style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'start',
                    rowGap:'14px'
                }}
                >
                <img className="collabrated-image" src={Telangana} />    
                <h3 className="locations" style={{
                    color:'white'
                }}>{Locations[0].heading}</h3>
                <p style={{
                    textAlign:'left',
                    color:'white'
                }} className="Lpara">{Locations[0].CTA}</p>
                <StarBorder
                  as="button"
                  className="custom-class"
                  color="cyan"
                  speed="5s"
                >
                  Get Quote Now
                </StarBorder>
                </div>
            </div>

        </section>
        </>
    )
};
export default Features;