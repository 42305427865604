import React from "react";
import aiImage from '../../img/Gemini_Generated_Image_k3kyh2k3kyh2k3ky.jpeg.jpg';
import Step1 from '../../img/_step 1 schedule your door pick up.svg';
import Step2 from '../../img/step 2 prepare your bike for shipment.svg';
import Step3 from '../../img/_step 3 shipping  your bike.svg';
import step4 from '../../img/step 4 door delivery.svg';

const SbsGuide = () => {
  return (
   <>
   <section style={{padding:'100px 0'}}>
    <div className="containersbsguide" style={{display:'flex'}}>
      <div className="leftandright">
        <div className="leftcontentboxes">
          <span className="steps-box">Step 1</span>
          <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />
          <div className="contentboxsmall">
            <img src={Step1} className="stepicons"/>
            <div>
              <h3 style={{textAlign: 'left'}}>Schedule Your Door Pick-Up</h3>
              <p style={{textAlign: 'left'}}>Schedule a convenient pickup time, and our team will collect your bike directly from your location for transport.</p>
              <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
            </div>
          </div>
        </div>
        <div className="leftcontentboxes">  <span className="steps-box">Step 2</span>
          <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />
          <div className="contentboxsmall">
            <img src={Step2} className="stepicons"/>
            <div>
              <h3 style={{textAlign: 'left'}}>Prepare Your Bike For Shipment</h3>
              <p style={{textAlign: 'left'}}>We inspect and securely pack your bike, disassembling if needed, and share pictures of the packed bike with you.</p>
              <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
            </div>
          </div> </div>
        <div className="leftcontentboxes">  <span className="steps-box">Step 3</span>
          <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />
          <div className="contentboxsmall">
            <img src={Step3} className="stepicons"/>
            <div>
              <h3 style={{textAlign: 'left'}}>Shipping Your Bike</h3>
              <p style={{textAlign: 'left'}}>Your bike is shipped using reliable methods, ensuring safe transport to its destination with on-time delivery guaranteed.</p>
              <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
            </div>
          </div> </div>
        <div className="leftcontentboxes">  <span className="steps-box">Step 4</span>
          <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />
          <div className="contentboxsmall">
            <img src={step4} className="stepicons"/>
            <div>
              <h3 style={{textAlign: 'left'}}>Door Delivery</h3>
              <p style={{textAlign: 'left'}}>We notify you once your bike arrives, arranging safe delivery, unpacking, and reassembling it so it's ready to ride.</p>
              <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
            </div>
          </div> </div>
      </div> 
      <div className="leftandright">
        <div className="rightimageboxes"><img className="inner-image"   src={Step1} /></div>
        <div className="rightimageboxes"><img className="inner-image"   src={Step2} /></div>
        <div className="rightimageboxes"><img className="inner-image"   src={Step3} /></div>
        <div className="rightimageboxes"><img className="inner-image"   src={step4} /></div>
      </div>
      </div>
   </section>
   <div>
   <div className="leftandright mobile-leftandright">
        <div className="leftcontentboxes">
          <span className="steps-box">Step 1</span>
          <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />
          <div className="contentboxsmall">
            <img src={Step1} className="stepicons"/>
            <div>
              <h3 style={{textAlign: 'left'}}>Schedule Your Door Pick-Up</h3>
              <p style={{textAlign: 'left'}}>Schedule a convenient pickup time, and our team will collect your bike directly from your location for transport.</p>
              <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
            </div>
          </div>
        </div>
        <div className="rightimageboxes"><img className="inner-image"  src={Step1} /></div>
        <div className="leftcontentboxes">  <span className="steps-box">Step 2</span>
          <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />
          <div className="contentboxsmall">
            <img src={Step2} className="stepicons"/>
            <div>
              <h3 style={{textAlign: 'left'}}>Prepare Your Bike For Shipment</h3>
              <p style={{textAlign: 'left'}}>We inspect and securely pack your bike, disassembling if needed, and share pictures of the packed bike with you.</p>
              <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
            </div>
          </div> </div>
          <div className="rightimageboxes"><img className="inner-image" src={Step2} /></div>
        <div className="leftcontentboxes">  <span className="steps-box">Step 3</span>
          <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />
          <div className="contentboxsmall">
            <img src={Step3} className="stepicons"/>
            <div>
              <h3 style={{textAlign: 'left'}}>Shipping Your Bike</h3>
              <p style={{textAlign: 'left'}}>Your bike is shipped using reliable methods, ensuring safe transport to its destination with on-time delivery guaranteed.</p>
              <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
            </div>
          </div> </div>
          <div className="rightimageboxes"><img className="inner-image" src={Step3} /></div>
        <div className="leftcontentboxes">  <span className="steps-box">Step 4</span>
          <hr style={{ border: "1px solid #ccc", margin: "20px 0" }} />
          <div className="contentboxsmall">
            <img src={step4} className="stepicons"/>
            <div>
              <h3 style={{textAlign: 'left'}}>Door Delivery</h3>
              <p style={{textAlign: 'left'}}>We Notify You Once Your Bike Arrives, Ensuring Safe Delivery, Unpacking, and Reassembling It So It's Ready to Ride.</p>
              <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
            </div>
          </div> </div>
          <div className="rightimageboxes"><img className="inner-image" src={step4} /></div>
      </div> 
   </div>
   </>
  )

}
export default SbsGuide;