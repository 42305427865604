import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import { basicInfoData } from "./data.js";
import { otherInfoData } from "./data.js";
import { OtherInfo } from "./otherInfo";
import { AddressSubForm } from "./addressSubForm";
import axios from "axios";

const getLocationCode = (type, location, val) => {
  const locationMap = {
    from: {
      Hyderabad: { id: 101, shortCode: "HYD" },
      Bangalore: { id: 102, shortCode: "BLR" },
      Mumbai: { id: 103, shortCode: "BOM" },
      Delhi: { id: 104, shortCode: "DLI" },
      Kalkata: { id: 105, shortCode: "CCU" },
      Chennai: { id: 106, shortCode: "MAA" },
      Vijayawada: { id: 107, shortCode: "VGA" },
      Vizag: { id: 108, shortCode: "VIZ" },
      Nellore: { id: 109, shortCode: "NLR" },
      Tirupati: { id: 110, shortCode: "TIR" },
      Pune: { id: 111, shortCode: "PUNE" },
      Other: { id: 0, shortCode: "OTH" },
    },
    to: {
      Hyderabad: { id: 1, shortCode: "HYD" },
      Bangalore: { id: 2, shortCode: "BLR" },
      Mumbai: { id: 3, shortCode: "BOM" },
      Delhi: { id: 4, shortCode: "DLI" },
      Kalkata: { id: 5, shortCode: "CCU" },
      Chennai: { id: 6, shortCode: "MAA" },
      Vijayawada: { id: 7, shortCode: "VGA" },
      Vizag: { id: 8, shortCode: "VIZ" },
      Nellore: { id: 9, shortCode: "NLR" },
      Tirupati: { id: 10, shortCode: "TIR" },
      Pune: { id: 11, shortCode: "PUNE" },
      Other: { id: 0, shortCode: "OTH" },
    },
  };

  return locationMap[type][location][val] || 0;
};

export function ChargesEstimation() {
  const [deliverySpeed, setDeliverySpeed] = React.useState("Standard");
  const [pickupType, setPickupType] = React.useState("Station Pickup");
  const [deliveryType, setDeliveryType] = React.useState("Station Delivery");
  const [damageInsurance, setDamageInsurance] = React.useState("Yes");
  const [formDetails, setFormDetails] = React.useState({});
  const [ senderdate, setSenderdate] = React.useState({});
  const [popUp, setPopUp] = React.useState(false);
  const location = useLocation();

  async function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formValues = Object.fromEntries(formData.entries());

    const bookingData = {
     bookingDate : new Date(formValues.bookingDate || Date.now()).toISOString(),
      pickupType: pickupType === "Door Pickup" ? 1 : 0,
      deliveryType: deliveryType === "Door Delivery" ? 1 : 0,
      damageScheme: damageInsurance === "No" ? "N" : "Y",
      assureScheme: "N",
      bookingType: 1,

      fromLocationDetails: {
        streetDoorpick: formValues.streetDoorPick || "",
        cityDoorpick: formValues.cityDoorPick || "",
        stateDoorpick: formValues.stateDoorPick || "",
        zipDoorpick: formValues.zipDoorPick || "",
      },
      fromCityId: getLocationCode("from", location.state.fromLocation, "id"),
      fromCityShortCode: getLocationCode(
        "from",
        location.state.fromLocation,
        "shortCode"
      ),
      fromLocation: location.state.fromLocation,

      toLocationDetails: {
        streetDoordel: formValues.streetDoordel || "",
        cityDoordel: formValues.cityDoordel || "",
        stateDoordel: formValues.stateDoordel || "",
        zipDoordel: formValues.zipDoordel || "",
      },
      toCityId: getLocationCode("to", location.state.toLocation, "id"),
      toCityShortcode: getLocationCode(
        "to",
        location.state.toLocation,
        "shortCode"
      ),
      toLocation: location.state.toLocation,

      priceDetails: (() => {
        const freightCharges = location.state.price || 0;
        const deliveryCharge = deliverySpeed === "Standard"? 0 : 200;
        const pickupCharge = pickupType === "Door Pickup" ? 500 : 0;
        const damageCharge = damageInsurance === "No" ? 0 : 100;
        const doordelCharge = deliveryType === "Door Delivery" ? 500 : 0;

        let totalCharges =
          freightCharges +
          deliveryCharge +
          pickupCharge +
          damageCharge +
          doordelCharge;
        const taxAmount = totalCharges * 0.18;

        totalCharges += taxAmount;

        return {
          freightCharges,
          deliveryCharge,
          pickupCharge,
          damageCharge,
          doordelCharge,
          totalCharges,
          taxAmount,
        };
      })(),

      senderName: formValues.senderName || "",
      senderEmail: formValues.senderEmail || "",
      senderMobileNumber: formValues.senderMobileNumber || "",
      senderdate: formValues.bookingDate || "",
    };

    // await axios
    //   .post("https://api.backend.movemybike.in/v1/cus/createorder", bookingData)
    //   .then((res) => {
    //     console.log(res);
    //   });
    setFormDetails(bookingData);
    setPopUp(true);
  }

  console.log("deliverySpeed", deliverySpeed);
  console.log('full details', formDetails);

  axios.post("https://api.backend.movemybike.in/v1/cus/p1", formDetails)
  .then((res) => {
    console.log('Form details response:', res);
  })
  .catch((error) => {
    console.error('Error sending form details:', error);
  });
    
    
  
  
 const PropPrice = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems:'center',
  width:'100%',
  paddingBottom: '10px',
    borderBottom: '1px solid #ced4da',
    flexGrow: '1',
    flexBasis: 'auto',
    flexWrap: 'wrap'
 }

  return (
    <div className="chargesEstimation">
      <div className="chargeEstimationContainer">
        <h2 style={{ fontSize: "24px" }}>Bike Transport Charges Estimation</h2>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "start" }}
        >
          <h4 style={{ flex: 1 }}>From: {location.state.fromLocation}</h4>
          <h4 style={{ flex: 1 }}>To:{location.state.toLocation}</h4>
        </div>
        <form onSubmit={handleSubmit} className="estimateFormContainer">
          <section className="basicInfoSection">
           {basicInfoData.map((inpEle, ind) => (
            <div className="basicInfoDiv" key={ind}>
              <label htmlFor={inpEle.id} className="estimateFormLabel">
                {inpEle.labelName} 
                {inpEle.sublabel && <span className="sublabel">{inpEle.sublabel}</span>}
              </label>
              <input
                type={inpEle.inputType}
                name={inpEle.inputName}
                id={inpEle.id}
                placeholder={inpEle.placeholder}
                className="estimateFormInput"
                required={!inpEle.optional} // Make 'required' dynamic based on 'optional'
              />
            </div>
          ))}
          </section>

          <section className="otherInfoSection">
            <OtherInfo
              info={otherInfoData[0]}
              setState={setDeliverySpeed}
              state={deliverySpeed}
            />
            <OtherInfo
              info={otherInfoData[1]}
              setState={setPickupType}
              state={pickupType}
            />
            {pickupType === "Door Pickup" && <AddressSubForm addType="Pick" />}
            <OtherInfo
              info={otherInfoData[2]}
              setState={setDeliveryType}
              state={deliveryType}
            />
            {deliveryType === "Door Delivery" && (
              <AddressSubForm addType="del" />
            )}
            <OtherInfo
              info={otherInfoData[3]}
              setState={setDamageInsurance}
              state={damageInsurance}
            />
          </section>

          <div>
            <button type="submit" style={{}}>
              Get Quote
            </button>
          </div>
        </form>
      </div>
      {popUp && (
        <div className={`sidePopup ${popUp ? "show" : "hidden"}`}>
          <div className="super-ui">
          <button
            className="closePopupBtn"
            onClick={() => setPopUp(false)}
            style={{ backgroundColor: "#bebebe", 
              width: '32px',
              height: '32px',
              display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               paddingTop: '14px',
             }}
          >
            &times;
          </button>
          <h4 className="align-left">Verify your Booking</h4>
          <p className="align-left"> Here are your estimated charges and details</p>
          <section className="grids-boxes">
          <section className="sub-box">
            <h6 style={{
              marginBottom: '22px',
    fontSize: '18px',
    color:'#b8111a'
            }}>User Details</h6>
            
            <p style={PropPrice} className="height62"> <strong> Name: </strong> {formDetails.senderName}</p>
            <p  className="height62"
            style={PropPrice}
            > <strong>Email: </strong>    <p className="valuebox"> {formDetails.senderEmail}        </p></p>
            <p className="height62" style={PropPrice}> <strong> Mobile:</strong> <p className="valuebox"> {formDetails.senderMobileNumber} </p></p>
            <p   className="height62"          style={PropPrice}> <strong> Date:  </strong> <p className="valuebox"> {formDetails.senderdate}         </p></p>
            
          </section>
          <section className="sub-box">
            <h6 style={{
              marginBottom: '22px',
    fontSize: '18px',
    color:'#b8111a'
            }}>Pickup Details</h6>
            <p className="height62" style={PropPrice} ><strong>Pickup Type:</strong> <p className="valuebox"> {pickupType} </p></p>
            {pickupType === "Door Pickup" && (
              <p className="height62" style={PropPrice}>
                <strong> Address: </strong> <p className="valueboxfull">  {formDetails.fromLocationDetails.streetDoorpick},{" "}
                {formDetails.fromLocationDetails.cityDoorpick},{" "}
                {formDetails.fromLocationDetails.stateDoorpick},{" "}
                {formDetails.fromLocationDetails.zipDoorpick} </p>
              </p>
            )}
            <p className="height62" style={PropPrice}> <strong>  From Location : </strong> <p className="valuebox"> {formDetails.fromLocation} </p></p>
          </section >
          <section className="sub-box">
            <h6 style={{
              marginBottom: '22px',
    fontSize: '18px',
    color:'#b8111a'
            }}>Delivery Details</h6>
            <p className="height62" style={PropPrice}><strong>Delivery Type: </strong> {deliveryType}</p>
            {deliveryType === "Door Delivery" && (
              <p className="height62" style={PropPrice}>
                <strong>Address:</strong> <p className="valueboxfull"> {formDetails.toLocationDetails.streetDoordel},{" "}
                {formDetails.toLocationDetails.cityDoordel},{" "}
                {formDetails.toLocationDetails.stateDoordel},{" "}
                {formDetails.toLocationDetails.zipDoordel}  </p>
              </p>
            )}
            <p className="height62" style={PropPrice}> <strong>  To Location : </strong> {formDetails.toLocation}</p>
          </section>
          <section className="sub-box">
            <h6 style={{
              marginBottom: '22px',
    fontSize: '18px',
    color:'#b8111a'
            }}>Charges Details</h6>
            <p className="height62" style={PropPrice}><strong> Route Charges: </strong>    <p className="valueboxes"> {formDetails.priceDetails.freightCharges}</p>  </p>
            <p className="height62" style={PropPrice}> <strong>  Delivery Charges:</strong><p className="valueboxes"> {formDetails.priceDetails.deliveryCharge}</p>  </p>
            <p className="height62" style={PropPrice}> <strong>  Pickup Charges:  </strong><p className="valueboxes"> {formDetails.priceDetails.pickupCharge}  </p>  </p>
            <p className="height62" style={PropPrice}> <strong>  Damage Charges:  </strong><p className="valueboxes"> {formDetails.priceDetails.damageCharge}  </p>  </p>
            <p className="height62" style={PropPrice}>  
              <strong> Door Delivery Charges: </strong>  {formDetails.priceDetails.doordelCharge}
            </p>
            <p className="height62" style={PropPrice}><strong>Tax Amount:  </strong> {formDetails.priceDetails.taxAmount}</p>
            <p  style={PropPrice} className="tatolaclass height62"> <strong> Total Charges: </strong> {formDetails.priceDetails.totalCharges}</p>
          </section>
          </section>
          </div>
          <div className="floatingbtn-complete" style={{
            position:'sticky',
            bottom:'0',
            left:'50%',
            transform:'translateX(-50%)',
            width:'300px',
            height:'42px',
            background:'#000',
            borderRadius:'14px',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            color:'white',
            fontFamily:'Satoshi'
          }}> Complete Order</div>
        </div>
      )}
    </div>
  );
}
