import React, {useEffect, useRef} from "react";
import serviceimage from "../../img/bike parcel service in hyderabad.jpg";
import leftimage from '../../img/BikeTransportation.png';
import ServiceImage from '../../img/packageservices.png';
import HometoHome from '../../img/home to home.svg';
import HometoStation from '../../img/hometostationnew.svg';
import StationtoHome from '../../img/staion to home.svg';
import StationtoStation from '../../img/station to station.svg';

const LandingPageservices = () => {
     



    const Content = [
        {
            heading: "Reliable Bike Parcel Services for Every Need",
            para: "Flexible bike transport across India with safe, affordable, door-to-door or station delivery."
        }
    ];

    const serviceCards = [
        { id: 1, imageSrc: HometoHome,  header: 'Home to Home',  description: "Convenient Bike Pickup and Delivery from Your Doorstep" },
        { id: 2, imageSrc: HometoStation,  header: 'Home to Station',  description: " Easy Pickup from Your Home, Delivered to the Nearest Station" },
        { id: 3, imageSrc: StationtoHome,  header: 'Station to Home',  description: "Pickup from Station, Delivered Safely to Your Door" },
        { id: 4, imageSrc: StationtoStation,  header: 'Station to Station',  description: "Reliable Bike Transport Between Stations Across India" }
    ];

    return (
        <>
        <section className="new-ui-services-section" style={{
            width: '100%',
            height:'100%',
            margin: '100px 0'
        }}>
            <div className="new-ui-services-section-inner-parent">
                <div className="image-box-for" style={{position:'relative'}}>
                    <img className="servicesimage" src={ServiceImage} style={{width:'555px', height:'77vh', objectFit:'cover', borderRadius: '24px', boxShadow:'rgb(0 0 0 / 15%) 4px 4px 20px 0px'}}/>
                </div>
                <div className="services-and-services">
                <div className="heading-part">
            <h3 className="text-left">{Content[0].heading}</h3>
            <p className="kovvu">{Content[0].para}</p>
            </div>
            <div>
            <div className="service-cards-container">
                    {serviceCards.map((card) => (
                        <div key={card.id} className="servicecard">
                            <img  src={card.imageSrc} alt={`Service ${card.id}`} />
                            <div className="needspace">
                            <h4 style={{textAlign:'left'}}>{card.header}</h4>
                            <p>{card.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

                </div>
            </div>
        </section>

        
        </>
    );
};

export default LandingPageservices;
