import React, { useState } from "react";
import { useEffect } from "react";
import { gsap } from 'gsap';

const Faqs = () => {

    useEffect(() => {
        gsap.set('.faq--answer', { autoAlpha: 0, height: 0 });
        gsap.set('.iconV, .iconH', { transformOrigin: '50% 50%' });
      
        let faqs = document.querySelectorAll('.faq');
      
        faqs.forEach(faq => {
          let answer = faq.querySelector('.faq--answer');
          let iconV = faq.querySelector('.iconV');
          let iconH = faq.querySelector('.iconH');
          
          let tl = gsap.timeline({ paused: true });
          
          tl.to(faq, { duration: 0.1, backgroundColor: '#F0F5F2' })
            .to(answer, { duration: 0.25, autoAlpha: 1, height: 'auto' }, "<-=.1")
            .to(iconV, { duration: 0.2, rotate: 135 }, '-=.15')  
            .to(iconH, { duration: 0.2, rotate: -42.5 }, '-=.15'); 
          
          tl.reverse();
          
          faq.addEventListener("click", () => {
            tl.reversed(!tl.reversed());
          });
    
          // Cleanup function to remove event listeners
          return () => {
            faq.removeEventListener("click", () => {
              tl.reversed(!tl.reversed());
            });
          };
        });
    }, []);

    const faqData = [
        {
            question: 'How do I schedule a bike pickup?',
            answer: 'You can schedule a pickup by contacting us directly or through our website at a convenient time.'
        },
        {
            question: 'Is my bike insured during transport?',
            answer: 'Yes, we offer insurance coverage for your bike during transport to ensure its safety.'
        },
        {
            question: 'How long does it take to deliver my bike?',
            answer: 'Delivery times vary based on the destination, but we ensure timely and reliable service for all locations.'
        },
        {
            question: 'What preparations do I need to make before shipping my bike?',
            answer: 'You should ensure your bike is clean, with minimal fuel, and all accessories are securely packed.'
        },
        {
            question: 'Can I track the status of my bike during transport?',
            answer: 'Yes, we provide regular updates and pictures of your packed bike, keeping you informed throughout the process.'
        },
        {
            question: 'Do you offer door-to-door delivery?',
            answer: 'Yes, we offer both door-to-door and station-to-station delivery options based on your preferences.'
        },
        {
            question: 'What happens if my bike is damaged during transport?',
            answer: 'The process involves taking the bike, importing it to the train, and delivering it to the customer.'
        },
        {
            question: 'How long does the shipping process take?',
            answer: 'We have a clear compensation process in case of any damages, and we handle such situations with full transparency.'
        },
        {
            question: 'How much does bike transport cost?',
            answer: 'The cost depends on the distance and service options, but we offer the most competitive rates in the market.'
        },
        
    ];

    

    return (
        <section>
            <div className="faqs">
                <div className="LR-containers h3s">
                    <h3>Frequently
                    Asked
                    Questions.</h3>
                    <p className="faq-para" style={{
                        textAlign: 'left'
                    }}>Find answers to common questions about our reliable and flexible bike transport services.</p>
                        <a href="#booknow" style={{
                textDecoration: 'none'
              }} ><button className="stepsbutton">Get quote now</button></a>
                </div>
                <div className="LR-containers">
                <div className="faqs-wrapper">
      {faqData.map((faq, index) => (
        <div key={index} className="faq">
          <div className="faq--title">
            <span>{faq.question}</span>
            <svg viewBox="0 0 24 24">
              <path className="iconV" d="M 12,0 V 24"/>
              <path className="iconH" d="M 0,12 H 24"/>
            </svg>
          </div>
          <div className="faq--answer">
            <div className="faq--answer-text">
              <p style={{
                textAlign:'left'
              }}>{faq.answer}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
                </div>
            </div>
        </section>
    );
};

export default Faqs;
