import React from "react";

const Offers = () => {

    const white = {
        
        color: 'white'
    };
    const whiteCenter = {
        color: 'white',
        textAlign: 'center'
    }
    return (
        <>
        <div className="container-for-offers">
            <div className="left-right-offers">
                <h2 style={white}>Grab a deal now</h2>
            </div>
            <div className="left-right-offers">
                <p>Slider will come here</p>
            </div>
        </div>
        </>
    )
};
export default Offers;