// import React from "react";

// export function OtherInfo({ info, setState, state }) {
//   const handleButtonClick = (buttonName) => {
//     setState(buttonName);
//   };
//   return (
//     <div className="otherInfoDiv">
//       <p
//         style={{
//           marginBottom: 0,
//           textAlign: "start",
//           flex: 1,
//         }}
//         className="estimateFormLabel"
//       >
//         {info.labelName}
//       </p>
//       <div
//         style={{
//           position: "relative",
//           display: "flex",
//           flex: 1,
//           border: "1px solid #A19494",
//           borderRadius: "50px",
//           backgroundColor: "white",
//           overflow: "hidden",
//           gap: "20px",
//         }}
//       >
//         <div
//           style={{
//             position: "absolute",
//             top: 0,
//             left: state === info.buttonOneLabel ? "0%" : "50%",
//             width: "50%",
//             height: "100%",
//             backgroundColor: "#2E2E2E",
//             borderRadius: "50px",
//             transition: "left 0.5s ease",
//             zIndex: 0,
//           }}
//         ></div>
//         <button
//           type="button"
//           onClick={handleButtonClick(info.buttonOneLabel)}
//           style={{
//             position: "relative",
//             backgroundColor: "transparent",
//             color: state === info.buttonOneLabel ? "white" : "#7D2E2E",
//             transition: "color 1s ease",
//             border: "none",
//             borderRadius: "50px",
//             flex: 1,
//             whiteSpace: "nowrap",
//             zIndex: 1,
//           }}
//         >
//           {info.buttonOneLabel}
//         </button>
//         <button
//           type="button"
//           onClick={handleButtonClick(info.buttonOneLabel)}
//           style={{
//             position: "relative",
//             backgroundColor: "transparent",
//             color: state === info.buttonTwoLabel ? "white" : "#7D2E2E",
//             transition: "color 1s ease",
//             border: "none",
//             borderRadius: "50px",
//             flex: 1,
//             whiteSpace: "nowrap",
//             zIndex: 1,
//           }}
//         >
//           {info.buttonTwoLabel}
//         </button>
//       </div>
//     </div>
//   );
// }

import React from "react";

export function OtherInfo({ info, setState, state }) {
  const handleOptionChange = (event) => {
    console.log('price',event.target.value);
    setState(event.target.value);
  };

  return (
    <div className="otherInfoDiv">
      <p
        style={{
          marginBottom: 0,
          textAlign: "start",
          flex: 1,
        }}
        className="estimateFormLabel"
      >
        {info.labelName}
      </p>
      <div
        style={{
          position: "relative",
          display: "flex",
          flex: 1,
          border: "1px solid #bebebe",
          borderRadius: "10px",
          backgroundColor: "white",
          overflow: "hidden",
          gap: "20px",
          padding: '7px',        }}
      >
        {/* Highlight Bar */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: state === info.buttonOneLabel ? "0%" : "50%",
            width: "50%",
            height: "100%",
            backgroundColor: "#2E2E2E",
            borderRadius: "0px",
            transition: "left 0.5s ease",
            zIndex: 0,
          }}
        ></div>

        {/* Radio Button One */}
        <label
          style={{
            position: "relative",
            backgroundColor: "transparent",
            color: state === info.buttonOneLabel ? "white" : "#7D2E2E",
            transition: "color 0.5s ease",
            borderRadius: "50px",
            flex: 1,
            textAlign: "center",
            cursor: "pointer",
            whiteSpace: "nowrap",
            zIndex: 1,
            fontFamily: 'movemybike',
          }}
        >
          {info.buttonOneLabel}
          <input
            type="radio"
            name="options"
            value={info.buttonOneLabel}
            checked={state === info.buttonOneLabel}
            onChange={handleOptionChange}
            style={{
              display: "none",
            }}
          />
        </label>

        {/* Radio Button Two */}
        <label
          style={{
            position: "relative",
            backgroundColor: "transparent",
            color: state === info.buttonTwoLabel ? "white" : "#7D2E2E",
            transition: "color 0.5s ease",
            borderRadius: "50px",
            flex: 1,
            textAlign: "center",
            fontFamily: 'movemybike',
            cursor: "pointer",
            whiteSpace: "nowrap",
            zIndex: 1,
          }}
        >
          {info.buttonTwoLabel}
          <input
            type="radio"
            name="options"
            value={info.buttonTwoLabel}
            checked={state === info.buttonTwoLabel}
            onChange={handleOptionChange}
            style={{
              display: "none",
            }}
          />
        </label>
      </div>
    </div>
  );
}
